var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-8",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","justify":"center","align":"start"}},[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products.items,"hide-default-footer":"","disable-sort":"","no-data-text":_vm.lang.emptyCartMessage,"mobile-breakpoint":0,"calculate-widths":""},scopedSlots:_vm._u([{key:"header.title",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-2 py-2 body-1 font-weight-medium"},[_vm._v(_vm._s(header.text))])]}},{key:"header.price",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-2 py-2 body-1 font-weight-medium"},[_vm._v(_vm._s(header.text))])]}},{key:"header.quantity",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-2 py-2 body-1 font-weight-medium"},[_vm._v(_vm._s(header.text))])]}},{key:"header.action",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-2 py-2 body-1 font-weight-medium"},[_vm._v(_vm._s(header.text))])]}},{key:"header.total",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-2 py-2 body-1 font-weight-medium"},[_vm._v(_vm._s(header.text))])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"full-height d-flex justify-start align-center"},[_c('div',{staticStyle:{"width":"125px"}},[_c('ImageComponent',{staticClass:"my-1",staticStyle:{"width":"125px","height":"125px"},attrs:{"product":item}})],1),_c('v-sheet',{staticClass:"transparent",attrs:{"max-width":"450px"}},[_c('v-list-item',{staticClass:"text-start",attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(item.connectorId))]),_c('v-list-item-title',{directives:[{name:"line-clamp",rawName:"v-line-clamp",value:(2),expression:"2"}],staticClass:"font-weight-bold text--secondary text-wrap",staticStyle:{"cursor":"pointer","overflow-wrap":"normal"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v("(Descripción).")])],1)],1)],1)],1)]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c('QuantitySelectorComponent',{attrs:{"product":item}})],1)]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold text--secondary text-h5"},[_vm._v(_vm._s(Number(item.commercialRate).toFixed(2))+"€")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-icon',{staticClass:"mr-1",on:{"click":_vm.addFavourite}},[_vm._v("mdi-star")]),_c('v-icon',{on:{"click":function($event){return _vm.removeProduct(item)}}},[_vm._v("mdi-close")])],1)]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold text--secondary text-h5"},[_vm._v(" "+_vm._s((Number(item.commercialRate) * item.quantity).toFixed(2))+"€ ")])]}}])})],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-sheet',{staticClass:"grey lighten-3 px-4 d-flex justify-center flex-column"},[_c('div',{staticClass:"grey--text text--darken-1 pa-2 mx-4"},[_c('h3',{staticClass:"grey--text text--darken-3 font-weight-medium"},[_vm._v("Total del Pedido:")]),_c('div',{staticClass:"mt-12 d-flex"},[_c('h3',[_vm._v("Subtotal:")]),_c('v-spacer'),_c('span',[_vm._v(_vm._s(_vm.getTotalTaxBase())+"€")])],1),_c('v-divider',{staticClass:"my-2"}),_c('div',{staticClass:"d-flex"},[_c('h3',[_vm._v("Total:")]),_c('v-spacer'),_c('span',[_vm._v(_vm._s(_vm.getTotalTaxBase())+"€")])],1)],1),_c('v-btn',{staticClass:"ma-2 px-16 py-7",attrs:{"tile":"","color":"primary","depressed":""},on:{"click":function($event){return _vm.$router.replace('/products')}}},[_vm._v(" "+_vm._s(_vm.lang.keepShoping)+" ")]),(_vm.isDistributor || _vm.isCommercial)?_c('v-btn',{staticClass:"ma-2 px-15 py-7 grey--text text--darken-2",attrs:{"tile":"","depressed":"","color":"success"},on:{"click":function($event){return _vm.goToCheckout('/checkout')}}},[_vm._v(" "+_vm._s(_vm.lang.goCheckout)+" ")]):_vm._e(),(_vm.isHeadquarterManager)?_c('v-btn',{staticClass:"ma-2 px-15 py-7 grey--text text--darken-2",attrs:{"tile":"","depressed":"","color":"success"},on:{"click":function($event){return _vm.goToCheckout('/checkout-headquarter')}}},[_vm._v(" "+_vm._s(_vm.lang.goHeadquarterCheckout)+" ")]):_vm._e(),_c('v-btn',{staticClass:"ml-auto py-7",attrs:{"text":"","tile":"","color":"primary","depressed":""},on:{"click":_vm.clearCart}},[_c('v-icon',[_vm._v("far fa-trash-alt")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }