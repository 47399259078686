














import Product from "@/models/Product"
import LoginService from "@/services/LoginService"
import CartTool from "@/services/tool/CartTool"
import SnackbarModule from "@/store/SnackbarModule"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"


@Component
export default class QuantitySelectorComponent extends Vue {
	@Prop() readonly product!: Product

	@Watch('product.quantity')
	onQuantityChanged() {
		CartTool.setProductQuantity(this.product.id!, this.product.quantity)
	}
}
