










import Product from "@/models/Product"
import ProductFeatureType from "@/models/ProductFeatureType"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import ConstantTool from "@/services/tool/ConstantTool"

@Component
export default class ImageComponent extends Vue {

  @Prop() readonly product!: Product
  BASE_URL = ConstantTool.BASE_URL
  image: string = ""

  created() {
    this.refresh()
  }

  errorHandler() {
    this.image = "../assets/ban.svg"
    this.$forceUpdate()
  }

  refresh() {
    this.image = `${this.BASE_URL}/storage/ftp/art_${this.product.connectorId?.toLowerCase()}_1_tmb.jpg`
  }

  @Watch('product')
  onProductChanged() {
    this.refresh()
  }
}
