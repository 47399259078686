import {AuthorityName} from "@/models/Authority";
import SessionModule from "@/store/SessionModule";
import {getModule} from "vuex-module-decorators";

export default class SessionTool {

    private static sessionModule = getModule(SessionModule)

    static hasAuthority(role: AuthorityName) {
        const authorities = this.sessionModule.session.authorities

        for (const authority of authorities) {
            if (authority.name === role) {
                return true
            }
        }

        return false
    }

    static getSession() {
        return this.sessionModule.session
    }

}