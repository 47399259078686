





























































































































import {Component, Mixins, Vue, Watch} from "vue-property-decorator"
import ProductListComponent from "@/components/ProductListComponent.vue"
import ImageComponent from "@/components/ImageComponent.vue"
import Product from "@/models/Product"
import ProductService from "@/services/ProductService"
import CartModule from "@/store/CartModule"
import CartTool from "@/services/tool/CartTool"
import { getModule } from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import SnackbarModule from "@/store/SnackbarModule"
import SessionModule from "@/store/SessionModule"
import QuantitySelectorComponent from "@/components/QuantitySelectorComponent.vue"
import Dialog from "@/models/vue/Dialog"
import DialogModule from "@/store/DialogModule"
import LoginService from "@/services/LoginService"
import Handler from "@/handlers/Handler";
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import SessionMixin from "@/mixins/SessionMixin";

@Component({ components: { ProductListComponent, ImageComponent, QuantitySelectorComponent } })
export default class CartView extends Mixins(SessionMixin) {

  loading: boolean = false
  products: MultipleItem<Product> = { items: [], totalItems: 0 }

  lang: any = getModule(LangModule).lang
  cartModule: CartModule = getModule(CartModule)
  sessionModule: SessionModule = getModule(SessionModule)

  get isLoged(): boolean { return LoginService.isLoged() }
  get cart() { return this.cartModule.cart }
  get rateId(): number { return this.sessionModule.session.user.rateId! }

  headers = [
    { text: this.lang.product, align: 'center', value: 'title' },
    { text: this.lang.price, align: 'center', value: 'price', width: '10%' },
    { text: this.lang.quantity, align: 'center', value: 'quantity', width: '10%' },
    { text: this.lang.action, align: 'center', value: 'action', width: '10%' },
    { text: this.lang.total, align: 'center', value: 'total', width: '10%' }
  ]

  created() {
    this.refresh()
  }

  async refresh() {
    try {
      await Handler.getItems(this, this.products, () =>
        ProductService.getProductsById2(this.cart)
      )
    } catch (e) { console.log(e) }
  }

  addFavourite() {
    if (this.isLoged) { getModule(SnackbarModule).makeToast(this.lang.addedNodes) } else {
      this.$router.push("/login")
    }
  }


  removeProduct(product?: Product) {
    if (product) { CartTool.removeProduct(product, this.products.items) }
    console.log(product)
  }

  goToCheckout(checkoutRoute: string) {
    if (this.products.items.length == 0) {
      this.lang.product
      getModule(SnackbarModule).makeToast(this.lang.noAddedProducts)
    } else {
      this.$router.replace(checkoutRoute)
    }
  }

  getTotalTaxBase(): number {
    let totalTaxBase: number = 0
    this.products.items.forEach(product => {
      totalTaxBase += Number(product.commercialRate) * Number(product.quantity)
    })

    return Number(totalTaxBase.toFixed(2))
  }

  async clearCart() {
    getModule(DialogModule).showDialog(new Dialog("Aviso", this.lang.emptyCartMessage, () => {
      CartTool.removeAllProducts()
      location.reload()
    }))
  }

  @Watch('cart')
  onCartChanged() {
    this.refresh()
  }

}
